import { Typography, Container, Breadcrumbs, Link, Drawer, IconButton, Divider, List, ListItemButton, ListItemIcon, ListItemText, Collapse, Box, Button, Toolbar, Menu, Avatar, Tooltip, MenuItem, CssBaseline, Radio, RadioGroup, FormControlLabel, FormControl, LinearProgress, LinearProgressProps,  } from '@mui/material'

import CircularProgress, { CircularProgressProps } from '@mui/material/CircularProgress';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';

import React, { useState } from 'react';


import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import LockIcon from '@mui/icons-material/Lock';
import S3Icon from 'react-aws-icons/dist/aws/logo/S3';
import MenuIcon from '@mui/icons-material/Menu';
import AdbIcon from '@mui/icons-material/Adb';

import { grey } from '@mui/material/colors';

import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';

import { styled, useTheme } from '@mui/material/styles';
import { width } from '@mui/system';

const pages = ['Tests', 'Resources', 'About'];
const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

const steps = [
    {
        label: 'Buckets',
        description: `Bucket management, permissions, storage classes, logging and monitoring.`,
    },
    {
        label: 'Objects & Keys',
        description:
            'Objects and keys, metadata, object lifecycle, storage classes and versioning.',
    },
    {
        label: 'S3 Security',
        description: `ACLs, Bucket policies, encryption and IAM roles`,
    },
];

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
    open?: boolean;
}>(({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    }),
}));


export function VerticalLinearStepper() {
    const [activeStep, setActiveStep] = React.useState(0);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleStart = () => {
        //
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const handleStep = (step) => () => {
        setActiveStep(step);
    };

    return (
        <Box sx={{ maxWidth: 400 }}>
            <Stepper nonLinear activeStep={activeStep} orientation="vertical">
                {steps.map((step, index) => (
                    <Step key={step.label}>
                        <StepLabel onClick={handleStep(index)} sx={{ cursor: 'pointer' }}>
                            {step.label}
                        </StepLabel>
                        <StepContent>
                            <Typography variant="caption">{step.description}</Typography>
                            <Box sx={{ mb: 2 }}>
                                <div>
                                    <Button
                                        variant="contained"
                                        onClick={handleStart}
                                        sx={{ mt: 1, mr: 1 }}
                                    >
                                        Start
                                    </Button>
                                    <Button
                                        disabled={index === steps.length}
                                        onClick={handleNext}
                                        sx={{ mt: 1, mr: 1 }}
                                    >
                                        Next
                                    </Button>
                                </div>
                            </Box>
                        </StepContent>
                    </Step>
                ))}
            </Stepper>
        </Box >
    );
}

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),

    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'space-between',
}));

function LinearProgressWithLabel(props: LinearProgressProps & { currentQuestion: number; totalQuestions: number }) {
    const progress = (props.currentQuestion / props.totalQuestions) * 100;
  
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ flexGrow: 1, mr: 1 }}>
          <LinearProgress variant="determinate" value={progress} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">
            {`${props.currentQuestion} of ${props.totalQuestions}`} {/* Display current question and total questions */}
          </Typography>
        </Box>
      </Box>
    );
  }
  

function CustomRadioGroup({ question, options }) {
    const [selectedValue, setSelectedValue] = useState(''); // No default value

    const handleChange = (event) => {
        setSelectedValue(event.target.value);
    };

    return (
        <Box display="flex" flexDirection="column" alignItems="center">
            <FormControl sx={{ margin: 'auto', width: 'fit-content', minWidth: 450, maxWidth: '80%', marginTop: 2 }}>
                {question && (
                    <Typography sx={{ marginBottom: 3 }}>
                        {question}
                    </Typography>
                )}
                <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    value={selectedValue}
                    name="radio-buttons-group"
                    onChange={handleChange}
                >
                    {options.map((option) => (
                        <Box 
                            key={option.id} 
                            sx={{ 
                                border: 1, 
                                borderRadius: 1, 
                                marginBottom: 1, 
                                width: '100%', 
                                backgroundColor: selectedValue === option.id ? 'rgba(0, 0, 255, 0.1)' : 'transparent',
                                '&:hover': {
                                    backgroundColor: selectedValue === option.id ? 'rgba(0, 0, 255, 0.1)' : 'rgba(0, 0, 0, 0.04)',
                                },
                            }}
                        >
                            <FormControlLabel 
                                value={option.id} 
                                control={<Radio />} 
                                label={option.label} 
                                sx={{ 
                                    width: '100%', 
                                    margin: 'auto', 
                                    wordBreak: 'break-word',
                                }} 
                            />
                        </Box>
                    ))}
                </RadioGroup>
            </FormControl>
        </Box>
    );
}

export default function App() {

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const theme = useTheme();

    const [open, setOpen] = React.useState(true);
    const [sectionOpen, setSectionOpen] = React.useState(true);
    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);


    const handleClick = () => {
        setSectionOpen(!sectionOpen);
    };

    const handleDrawerOpen = () => {
        setOpen(true);
    };
    const handleDrawerClose = () => {
        setOpen(false);
    };

    function CircularWithValueLabel() {
        const [progress, setProgress] = React.useState(10);

        // React.useEffect(() => {
        //     const timer = setInterval(() => {
        //         setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 10));
        //     }, 800);
        //     return () => {
        //         clearInterval(timer);
        //     };
        // }, []);

        return <CircularProgressWithLabel value={progress} />;
    }

    function CircularProgressWithLabel(
        props: CircularProgressProps & { value: number },
    ) {
        return (
            <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                <CircularProgress variant="determinate" {...props} />
                <Box
                    sx={{
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        position: 'absolute',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Typography
                        variant="caption"
                        component="div"
                        color="text.secondary"
                    >{`${Math.round(props.value)}%`}</Typography>
                </Box>
            </Box>
        );
    }

    function BottomButtons() {
        const handleCheckAnswer = () => {
            // Implement your check answer logic here
            console.log('Checking answer...');
        };
    
        const handleSkip = () => {
            // Implement your skip logic here
            console.log('Skipping...');
        };
    
        return (
            <Box
                sx={{
                    position: 'fixed',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    backgroundColor: 'background.paper',
                    borderTop: 1,
                    borderColor: 'divider',
                    padding: 2,
                    display: 'flex',
                    justifyContent: 'flex-end', // Aligns items to the right
                    gap: 2, // Adds space between the buttons
                }}
            >
                <Button variant="contained" color="primary" onClick={handleCheckAnswer}>
                    Check Answer
                </Button>
                <Button variant="contained" color="secondary" onClick={handleSkip}>
                    Skip
                </Button>
            </Box>
        );
    }
    

    const options = [
        { id: '1234-1234-1234-1235', label: 'No specialist knowledge required' },
        { id: '1234-1234-1234-1236', label: 'Agility: decrease time to start new services' },
        { id: '1234-1234-1234-1237', label: 'Elasticity: no need to worry about capacity' },
        { id: '1234-1234-1234-1238', label: 'Variable expense' },
        { id: '1234-1234-1234-1239', label: 'Requires large amounts of capital' }
    ];


    return (
        <>

            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <AppBar position="fixed" open={open}>
                    <Toolbar>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                            edge="start"
                            sx={{ mr: 2, ...(open && { display: 'none' }) }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Container maxWidth="xl">
                            <Toolbar disableGutters>
                                <AdbIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} />
                                <Typography
                                    variant="h6"
                                    noWrap
                                    component="a"
                                    href="#app-bar-with-responsive-menu"
                                    sx={{
                                        mr: 2,
                                        display: { xs: 'none', md: 'flex' },
                                        // fontFamily: 'monospace',
                                        fontWeight: 700,
                                        letterSpacing: '.1rem',
                                        color: 'inherit',
                                        textDecoration: 'none',
                                    }}
                                >
                                    MOCKQUEST
                                </Typography>

                                <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                                    <IconButton
                                        size="large"
                                        aria-label="account of current user"
                                        aria-controls="menu-appbar"
                                        aria-haspopup="true"
                                        onClick={handleOpenNavMenu}
                                        color="inherit"
                                    >
                                        <MenuIcon />
                                    </IconButton>
                                    <Menu
                                        id="menu-appbar"
                                        anchorEl={anchorElNav}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'left',
                                        }}
                                        keepMounted
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'left',
                                        }}
                                        open={Boolean(anchorElNav)}
                                        onClose={handleCloseNavMenu}
                                        sx={{
                                            display: { xs: 'block', md: 'none' },
                                        }}
                                    >
                                        {pages.map((page) => (
                                            <MenuItem key={page} onClick={handleCloseNavMenu}>
                                                <Typography textAlign="center">{page}</Typography>
                                            </MenuItem>
                                        ))}
                                    </Menu>
                                </Box>
                                <AdbIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} />
                                <Typography
                                    variant="h5"
                                    noWrap
                                    component="a"
                                    href="#app-bar-with-responsive-menu"
                                    sx={{
                                        mr: 2,
                                        display: { xs: 'flex', md: 'none' },
                                        flexGrow: 1,
                                        fontFamily: 'monospace',
                                        fontWeight: 700,
                                        letterSpacing: '.3rem',
                                        color: 'inherit',
                                        textDecoration: 'none',
                                    }}
                                >
                                    LOGO
                                </Typography>
                                <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                                    {pages.map((page) => (
                                        <Button
                                            key={page}
                                            onClick={handleCloseNavMenu}
                                            sx={{ my: 2, color: 'white', display: 'block' }}
                                        >
                                            {page}
                                        </Button>
                                    ))}
                                </Box>

                                <Box sx={{ flexGrow: 0 }}>
                                    <Tooltip title="Open settings">
                                        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                            <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
                                        </IconButton>
                                    </Tooltip>
                                    <Menu
                                        sx={{ mt: '45px' }}
                                        id="menu-appbar"
                                        anchorEl={anchorElUser}
                                        anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        keepMounted
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        open={Boolean(anchorElUser)}
                                        onClose={handleCloseUserMenu}
                                    >
                                        {settings.map((setting) => (
                                            <MenuItem key={setting} onClick={handleCloseUserMenu}>
                                                <Typography textAlign="center">{setting}</Typography>
                                            </MenuItem>
                                        ))}
                                    </Menu>
                                </Box>
                            </Toolbar>
                        </Container>
                    </Toolbar>
                </AppBar>
                <Drawer
                    sx={{
                        width: drawerWidth,
                        flexShrink: 0,
                        '& .MuiDrawer-paper': {
                            width: drawerWidth,
                            boxSizing: 'border-box',
                        },
                    }}
                    anchor="left"
                    variant="persistent"
                    open={open}
                >
                    <DrawerHeader>
                        <Box display="flex" alignItems="center" justifyContent={'flex-start'}>
                            <Typography sx={{ padding: 1 }}>My Progress</Typography>
                            <CircularWithValueLabel variant="determinate" value={25} />
                        </Box>
                        <IconButton onClick={handleDrawerClose}>

                            <ChevronLeftIcon />
                        </IconButton>
                    </DrawerHeader>
                    <Divider />
                    <List >
                        <ListItemButton onClick={handleClick}>
                            <ListItemIcon>
                                <S3Icon />
                            </ListItemIcon>
                            <ListItemText primary="S3" />
                            {sectionOpen ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton >
                        <Collapse in={sectionOpen} timeout="auto" unmountOnExit sx={{ bgcolor: grey[100], color: 'text.primary', p: 2 }}>
                            <VerticalLinearStepper />
                        </Collapse>
                        <ListItemButton>
                            <ListItemIcon>
                                <LockIcon />
                            </ListItemIcon>
                            <ListItemText primary="EC2" />
                        </ListItemButton>
                        <ListItemButton>
                            <ListItemIcon>
                                <LockIcon />
                            </ListItemIcon>
                            <ListItemText primary="IAM" />
                        </ListItemButton>
                    </List>
                </Drawer>
                <Main open={open}>
                    <DrawerHeader />
                    <Breadcrumbs maxItems={2} aria-label="breadcrumb" sx={{ paddingBottom: 1 }}>
                        <Link underline="hover" color="inherit" href="#">
                            Home
                        </Link>
                        <Link underline="hover" color="inherit" href="#">
                            AWS
                        </Link>
                    </Breadcrumbs>

                    <Box sx={{ minWidth:450, width:'60%', margin: 'auto'}}>
                        <LinearProgressWithLabel currentQuestion={1} totalQuestions={12} />
                    </Box>

                    <CustomRadioGroup
                        question="Which of the following describe cloud computing's benefits? (Choose one option)"
                        options={options}
                    />

                    <BottomButtons/>

                </Main>
            </Box>
        </>
    )
}
